import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { PlotModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import { toast } from "react-toastify";
import withApiUser from "../../../utils/useUserCompanyRole";
import { useRecoilValue } from "recoil";
import { userState } from "../../../atoms/user";

interface PlotDetailsTabProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
  siteId: number;
}

type Inputs = {
  name: string;
  construction_method: string;
  property_type: string;
  location: string;
  stage_inspection: string;
};

const PlotDetailsTab: React.FC<PlotDetailsTabProps> = ({
  plot,
  getPlot,
  siteId,
}) => {
  const [loading, setLoading] = useState(false);
  const authUser = useRecoilValue(userState);

  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: plot,
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (loading) return;

    setLoading(true);
    try {
      await axios.put(`/admin/sites/${siteId}/plots/${plot.id}`, {
        ...formData,
        users: JSON.stringify(plot.users),
        regime: JSON.stringify(plot.regime),
      });
      toast("Plot details saved!", { type: "success" });
      getPlot();
    } catch (error) {
      toast("Failed to update plot details", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Plot Name:</label>
          <input
            className="text-[#ff1616] font-medium bg-gray rounded-md border-none block w-full"
            {...register("name", { required: true, minLength: 3 })}
            type="text"
            required
            readOnly={authUser?.role === "user"}
          ></input>
        </div>

        <div className="my-4">
          <label htmlFor="location" className="text-sm font-medium mb-2">
            Construction Method
          </label>
          <select
            id="construction_method"
            {...register("construction_method", { required: true, minLength: 1 })}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#fe5151] focus:border-[#fe5151] block w-full p-2.5 placeholder:text-gray-400"
            placeholder="Choose a construction method"
          >
            <option selected disabled>
              Choose a construction method
            </option>
            <option value="timber_frame">Timber Frame</option>
            <option value="masonry">Masonry</option>
            <option value="steel_frame">Steel Frame</option>
          </select>
          {/* {errors.construction_method && (
            <span className="mt-2 text-xs text-red-600 font-medium">
              Construction Method is required
            </span>
          )} */}
        </div>

        {
          plot.property_type.toLowerCase() !== 'apartment' && (
            <div className="my-4">
              <label htmlFor="warranty_provider" className="text-sm font-medium mb-2">
                Property type
              </label>
              <select
                id="property_type"
                {...register("property_type", { required: true, minLength: 1 })}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#fe5151] focus:border-[#fe5151] block w-full p-2.5 placeholder:text-gray-400"
              >
                <option selected disabled>
                  Choose a plot type
                </option>
                <option value="terraced_house">Terraced House</option>
                <option value="semi_detached_house">Semi Detached House</option>
                <option value="detached_house">Detached House</option>
                <option value="semi_detached_bungalow">Semi Detached Bungalow</option>
                <option value="detached_bungalow">Detached Bungalow</option>
                <option value="maisonette">Maisonette</option>
              </select>
              {/* {errors.property_type && (
                <span className="mt-2 text-xs text-red-600 font-medium">
                  Property type is required
                </span>
              )} */}
            </div>
          )
        }

        <div>
          {authUser?.role !== "user" && (
            <button
              type="submit"
              disabled={loading}
              className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-sm"
            >
              {loading ? "Loading..." : "Save Plot details"}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default withApiUser(PlotDetailsTab);
