import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { AiOutlineEdit } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PaginatedType, PlotModel, SiteModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import { PulseLoader } from "react-spinners";
import FailedToLoadDetails from "../../../components/FailedToLoadDetails";
import ListViewHeader from "../../../components/ListViewHeader";
import useCustomSearchParams from "../../../utils/useCustomSearchParams";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import Table from "../../../components/Table";
import CreatePlotForm from "../../../forms/CreatePlotForm";
import Modal from "../../../components/Modal";
import CreateBlockForm from "../../../forms/CreateBlockForm";
import EditSiteForm from "../../../forms/EditSiteForm";
import { CSVLink } from "react-csv";
import withApiUser from "../../../utils/useUserCompanyRole";
import { useRecoilValue } from "recoil";
import { userState } from "../../../atoms/user";
import { FaUsersCog } from "react-icons/fa";
import { toast } from "react-toastify";


const convertToTitleCase = (str: string) => {
  return str
      .split('_') // Split the string at each underscore
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
      .join(' '); // Join words with a space
}

const SitesShowScreen: React.FC = () => {
  const navigate = useNavigate();
  let { siteId } = useParams();
  const authUser = useRecoilValue(userState);

  const [showEdit, setShowEdit] = useState(false);
  const [showAddPlot, setShowAddPlot] = useState(false);
  const [showAddBlock, setShowAddBlock] = useState(false);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [site, setSite] = useState<SiteModel | null>();

  const [blocksList, setBlocksList] = useState<any>([]);

  const getSiteById = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<SiteModel>(`/admin/sites/${siteId}`);
      setSite(data);
      setError(false);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  }, [siteId]);

  useEffect(() => {
    getSiteById();
  }, [getSiteById]);

  const [listError, setListError] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [list, setList] = useState<PlotModel[]>([]);
  const [search] = useCustomSearchParams();
  const [listTotal, setListTotal] = useState(0);

  const [csvRows, setCsvRows] = useState<PlotModel[]>([]);
  const csvData = useMemo(() => {
    return [
      [
        "Name",
        "Not Started",
        "In Progress",
        "Completed",
        "Construction Method",
        "Property Type",
        "Block",
        "Floors",
        "Created By",
        "Created At",
        "Status",
      ],
      ...csvRows.map((li) => [
        li.name,
        li.notStarted,
        li.inProgress,
        li.completed,
        convertToTitleCase(li.construction_method),
        convertToTitleCase(li.property_type),
        li.block?.name ?? "-",
        li.floors_no,
        li.created_by,
        li.created_at,
        convertToTitleCase(li.status),
      ]),
    ];
  }, [csvRows]);

  const getFullPlotsList = async () => {
    const { data } = await axios.get<PlotModel[]>(
      `/admin/sites/${siteId}/plots/all`,
      {
        params: {
          search: search.search,
        },
      }
    );

    const plotsData = data.map((plot) => {
      const { regime, reports } = plot; // Destructure regime and reports
      const sections = analyzeSections(regime, reports); // Call analyzeSections
  
      return {
          ...plot,
          notStarted: sections.notStarted,
          inProgress: sections.inProgress,
          completed: sections.completed
      };
    });

    setCsvRows(plotsData);
  };

  const getBlocksList = async () => {
    try {
      const { data } = await axios.get<any[]>(
        `/admin/sites/${siteId}/blocks/all`
      );
      setBlocksList(data);
    } catch (error) {}
  };
  const pagination = useMemo(() => {
    return {
      current_page: parseInt(search.page ?? "1"),
      per_page: parseInt(search.per_page ?? "20"),
    };
  }, [search.page, search.per_page]);

  const getBlocksPlotsList = useCallback(async () => {
    setListLoading(true);
    try {
      const { data } = await axios.get<PaginatedType<PlotModel[]>>(
        `/admin/sites/${siteId}/plots`,
        {
          params: {
            page: pagination.current_page,
            per_page: pagination.per_page,
            search: search.search,
          },
        }
      );

    const plotsData = data.items.map((plot) => {
      const { regime, reports } = plot; // Destructure regime and reports
      const sections = analyzeSections(regime, reports); // Call analyzeSections
  
      return {
          ...plot,
          notStarted: sections.notStarted,
          inProgress: sections.inProgress,
          completed: sections.completed
      };
    });

      setList(plotsData);
      setListTotal(data.total);
    } catch (error) {
      setListError(true);
      setList([]);
      setListTotal(0);
    }
    setListLoading(false);
  }, [pagination.current_page, pagination.per_page, search.search, siteId]);

  const deleteSite = async () => {
    if (
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to permanently delete this site, this cannot be undone!"
      )
    ) {
      try {
        await axios.delete(`/admin/sites/${siteId}`);
        toast("Site has been deleted successfully", {
          type: "success",
        });

        navigate("/sites");
      } catch (error) {
        toast("Failed to delete site, please contact support", {
          type: "error",
        });
      }
    }
  };

  const analyzeSections = (regime: any, reports: any) => {
    // Convert reports array to an object with type_id as keys for quick lookup
    const reportStatus = reports.reduce((acc: any, report: any) => {
      acc[`${report.room_or_comm_id}-${report.type_id}`] = report.completed || false; // Defaults to false if completed is undefined
      return acc;
    }, {});

    // Initialize counts
    let notStartedCount = 0;
    let inProgressCount = 0;
    let completedCount = 0;

    // Iterate over each floor in the regime
    for (const floor in regime) {
        if (regime.hasOwnProperty(floor)) {
            // Iterate over each section in the floor
            for (const section of regime[floor]) {
              const sectionName = `${floor}-${section}`;
                if (!(sectionName in reportStatus)) {
                    // Not started if section is not in reports
                    notStartedCount++;
                } else if (reportStatus[sectionName]) {
                    // Completed if report status is true
                    completedCount++;
                } else {
                    // In progress if report status is false
                    inProgressCount++;
                }
            }
        }
    }

    // Return the result as an object
    return {
        notStarted: notStartedCount,
        inProgress: inProgressCount,
        completed: completedCount
    };
  }

  useEffect(() => {
    getBlocksPlotsList();
    getBlocksList();
    getFullPlotsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBlocksPlotsList, pagination, search.search]);

  if (error) return <FailedToLoadDetails />;
  if (!site || loading)
    return (
      <div className="p-6 text-gray-500 font-medium text-lg">
        <PulseLoader color="#ff1616" size={10} />
      </div>
    );

  return (
    <div>
      <div className="p-6">
        <div
          role="button"
          className="flex items-center text-xs font-medium text-gray-500 hover:text-black"
          onClick={() => {
            navigate(`/sites`);
          }}
        >
          <IoArrowBackOutline className="mr-2" />
          Back to sites list
        </div>
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-[#ff1616] text-xl font-medium my-6">{site.name}</h1>
          </div>
          <div>
            <button
              onClick={deleteSite}
              className=" bg-red-500 text-white p-2 px-6 rounded-md shadow-sm text-sm"
            >
              Delete Site
            </button>
          </div>
        </div>
        <div className="flex">
          <p className="text-gray-500 mr-6">
            Location: <strong className="text-black">{site.location}</strong>
          </p>
          <p className="text-gray-500 mr-6">
            Warranty Provider:{" "}
            <strong className="text-black">{site.warranty_provider}</strong>
          </p>
          <p className="text-gray-500 mr-6">
            Registration Date:{" "}
            <strong className="text-black">{new Date(site.registration_date).toLocaleDateString()}</strong>
          </p>
          <p className="text-gray-500 mr-6">
            Approved Inspector/LABC:{" "}
            <strong className="text-black">{site.inspector}</strong>
          </p>
          {authUser?.role !== "user" && (
            <div role="button" onClick={() => setShowEdit(true)}>
              <AiOutlineEdit size={23} />
            </div>
          )}
        </div>
      </div>
      <div>
        <ListViewHeader
          title="Plots"
          showSearch
          actions={
            <div className="flex items-center justify-end">
              {authUser?.role !== "user" && (
                <>
                  <button
                    onClick={() => setShowAddBlock(true)}
                    className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-md mr-2"
                  >
                    Create Block
                  </button>
                  <button
                    onClick={() => setShowAddPlot(true)}
                    className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-md mr-2"
                  >
                    Create Plot
                  </button>
                </>
              )}
              <CSVLink
                data={csvData}
                asyncOnClick={true}
                onClick={(event, done) => getFullPlotsList()}
                filename="Plots"
                className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-md"
              >
                CSV Download
              </CSVLink>
              <Link
                to={`/sites/${site.id}/manage-users`}
                className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-md block h-10 ml-2"
              >
                <FaUsersCog size={20} />
              </Link>
            </div>
          }
        />
        <Table
          total={listTotal}
          loading={listLoading}
          error={listError}
          rows={list}
          pagination={pagination}
          headers={[
            "Name",
            "Not Started",
            "In Progress",
            "Completed",
            "Construction Method",
            "Property Type",
            "Block",
            "Floors",
            "Created By",
            "Created At",
            "Status"
          ]}
          messages={{
            noResults: "No blocks or plots found, create one above",
            apiFailed:
              "Failed to load plots list, contact support if this persists.",
          }}
          renderRow={(plot: PlotModel) => {
            // const type = Object.hasOwn(plotOrBlock, "property_type")
            //   ? "plot"
            //   : "block";
            // const plbItem = Object.hasOwn(plotOrBlock, "property_type")
            //   ? (plotOrBlock as PlotModel)
            //   : (plotOrBlock as BlockModel);

            return (
              <tr
                key={plot.id}
                className="my-2 p-3 border-b border-gray-200 last:border-b-0 text-gray-500"
              >
                <td className="w-[300px] min-w-[300px] max-w-[300px]  py-3 pl-6">
                  <Link
                    to={`/sites/${siteId}/plots/${plot.id}`}
                    style={{ fontSize: '12px' }}
                    className="text-[#ff1616] hover:text-[#fe5151] flex items-center justify-start"
                  >
                    {plot.name}
                  </Link>
                </td>
                <td className="w-[100px] min-w-[100px] max-w-[100px] px-4">
                  <div style={{
                    backgroundColor: '#F0F1F0',
                    borderRadius: '5px',
                    padding: '2px 10px',
                    width: '50px',
                    textAlign: 'center',
                    fontSize: '12px',
                    border: '2px solid #a1a1a1'
                  }}>
                    {plot.notStarted}
                  </div>
                </td>
                <td className="w-[100px] min-w-[100px] max-w-[100px] px-2">
                  <div style={{
                    backgroundColor: '#ffff00',
                    borderRadius: '5px',
                    padding: '2px 10px',
                    width: '50px',
                    color: '#cfbe23',
                    textAlign: 'center',
                    border: '2px solid #e8d20c',
                    fontSize: '12px'
                  }}>
                    {plot.inProgress}
                  </div>
                </td>
                <td className="w-[100px] min-w-[100px] max-w-[100px] px-2">
                  <div style={{
                    backgroundColor: '#bdffbd',
                    borderRadius: '5px',
                    padding: '2px 10px',
                    width: '50px',
                    color: '#18ce2e',
                    textAlign: 'center',
                    border: '2px solid #18ce2e',
                    fontSize: '12px'
                  }}>
                    {plot.completed}
                  </div>
                </td>
                <td className="w-[150px] min-w-[150px] max-w-[150px] py-2" style={{ fontSize: '12px' }}>{convertToTitleCase(plot.construction_method) ?? "-"}</td>
                <td className="w-[150px] min-w-[150px] max-w-[150px] py-2" style={{ fontSize: '12px' }}>{convertToTitleCase(plot.property_type) ?? "-"}</td>
                <td className="w-[150px] min-w-[150px] max-w-[150px] py-2" style={{ fontSize: '12px' }}>
                  {plot.block ? (
                    <Link
                      to={`/sites/${siteId}/blocks/${plot.block.id}`}
                      style={{ fontSize: '12px' }}
                      className="text-[#ff1616] hover:text-[#fe5151] flex items-center justify-start"
                    >
                      {plot.block.name}
                    </Link>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="w-[100px] min-w-[100px] max-w-[100px] py-2" style={{ fontSize: '12px' }}>{Math.max(plot.floors_no, 1)}</td>
                <td className="w-[200px] min-w-[200px] max-w-[200px]  py-2" style={{ fontSize: '12px' }}>{plot.created_by}</td>
                <td className="w-[150px] min-w-[150px] max-w-[150px]" style={{ fontSize: '12px' }}>
                  {new Date(plot.created_at).toLocaleDateString()}
                </td>
                <td className="w-[150px] min-w-[150px] max-w-[150px]" style={{ fontSize: '12px' }}>{plot.active ? convertToTitleCase(plot.status) : "Draft"}</td>

                {/* <td>
                  <Link
                    to={`/sites/${siteId}/plots/${plot.id}`}
                    className="text-gray-400 hover:text-[#fe5151] cursor-pointer"
                  >
                    <HiOutlineDotsHorizontal size={20} />
                  </Link>
                </td> */}
              </tr>
            );
          }}
        />
      </div>

      <div className="mb-20">
        <ListViewHeader title="Blocks" />
        <div className="px-6">
          {blocksList.length === 0 && (
            <p className="text-sm text-gray-600">No blocks found</p>
          )}
          <div className="grid grid-cols-5 gap-6">
            {blocksList.map((block: any) => (
              <Link
                to={`/sites/${siteId}/blocks/${block.id}`}
                className="bg-white rounded-md p-4 block text-center shadow-sm"
                onClick={(e) => {
                  if (block.plots.length > 0 || authUser?.role === "user") {
                    e.preventDefault();
                    return;
                  }
                }}
              >
                <h3 className="font-semibold text-lg">
                  {block.name}{" "}
                  {block.plots.length === 0 && (
                    <strong className="font-extrabold text-xs text-[#ff1616]">
                      (DRAFT)
                    </strong>
                  )}
                </h3>
                <div className="text-gray-600 text-sm">
                  <p>
                    Construction Method:{" "}
                    <strong>{convertToTitleCase(block.construction_method)}</strong>
                  </p>
                  <p>
                    Floors: <strong>{block.floors_no}</strong> | Plots:{" "}
                    <strong>{block.plots_no}</strong> | Communals:{" "}
                    <strong>{block.communals_no}</strong>
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <Modal shown={showAddPlot} onClose={() => setShowAddPlot(false)}>
        <CreatePlotForm siteId={site.id} onCompleted={async () => {}} />
      </Modal>

      <Modal shown={showAddBlock} onClose={() => setShowAddBlock(false)}>
        <CreateBlockForm siteId={site.id} onCompleted={async () => {}} />
      </Modal>
      <Modal shown={showEdit} onClose={() => setShowEdit(false)}>
        <EditSiteForm
          site={site}
          onCompleted={async () => {
            await getSiteById();
            setShowEdit(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default withApiUser(SitesShowScreen);
