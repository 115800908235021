import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PlotModel, ReportModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import { getActualSectionName, getFloorName, getSectionName } from "../../../utils/stringUtil";
import { CSVLink } from "react-csv";
import { getApiFileURL } from "../../../utils/files";
import { IoCloseOutline } from "react-icons/io5";

interface PlotReportsTabProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
  siteId: number;
}

const PlotReportsTab: React.FC<PlotReportsTabProps> = ({ siteId, plot }) => {
  const [reports, setReports] = useState<ReportModel[]>([]);
  const [dialogOpen, setDialogOpen] = useState({
    image: '',
    geoTag: '',
    isOpen: false
  });

  const getReportsList = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `/admin/sites/${siteId}/plots/${plot.id}/reports`
      );

      setReports(data);
    } catch (error) {}
  }, [siteId, plot.id]);

  useEffect(() => {
    getReportsList();
  }, [getReportsList]);

  const csvData = useMemo(() => {
    return [
      ["Section", "Subsection", "Floor", "Details", "Geotag", "Completed", "Created At"],
      ...reports.map((report) => [
        getActualSectionName(report.type_id),
        getSectionName(report.type_id),
        plot?.floors_no === 0 ? 'Single Floor' : getFloorName(report.room_or_comm_id),
        report.details,
        report.geo_tag_1,
        report.completed ? "Yes" : "No",
        report.created_at,
      ]),
    ];
  }, [reports]);

  return (
    <div className="py-4 px-0 relative">
      <div className="relative" style={{ minHeight: 300 }}>
        <CSVLink
          data={csvData}
          filename="Reports"
          className="bg-[#ff1616] text-white hover:bg-[#fe5151] p-2 px-6 rounded-md shadow-sm text-md"
        >
          CSV Download
        </CSVLink>
        <table className="table-auto w-full text-left bg-white rounded-t-md mt-6">
          <thead className="mb-2 h-12 border-b border-gray-100">
            <tr className="text-gray-400 font-light">
              <th className="p-2 pl-6">Section</th>
              <th className="p-2 pl-6">Subsection</th>
              <th>Floor</th>
              <th>Details</th>
              <th>Images</th>
              <th>Marked Complete</th>
              <th>Created By</th>
              <th>Created At</th>

              {/* {headers.map((header, index) => {
                if (index === 0) return <th className="p-2 pl-6">{header}</th>;
                if (header === "") return <th className="w-10"></th>;
                return <th>{header}</th>;
              })} */}
            </tr>
          </thead>
          <tbody>
            {reports.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500 text-sm"
                >
                  <td className="py-3 pl-6 max-w-[150px] font-medium text-black">
                    {getActualSectionName(row.type_id)}
                  </td>
                  <td className="py-3 pl-6 max-w-[150px] font-medium text-black">
                    {getSectionName(row.type_id)}
                  </td>
                  <td className="py-2 font-medium">
                    {plot?.floors_no === 0 ? 'Single Floor' : getFloorName(row.room_or_comm_id)}
                  </td>
                  <td className="py-2 max-w-[180px] text-sm">{row.details}</td>

                  <td className="py-2 flex flex-wrap min-w-[200px]">
                    <RowAttachments setDialogOpen={setDialogOpen} row={row} />
                  </td>
                  <td className="py-2" > <p className={row.completed ? 'text-[#17a34a]' : 'text-black'}>{row.completed ? "Yes" : "No"} </p></td>
                  <td className="py-2">
                    {row.created_by?.email || "N/A"}
                  </td>
                  <td className="py-2">
                    {new Date(row.created_at).toLocaleDateString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {
          dialogOpen.isOpen && (
            <div className="fixed top-0 left-0 bg-black bg-opacity-50 w-screen h-screen flex items-center justify-center overscroll-none p-4 z-50">
              <dialog
                className="dialog h-[540px]"
                open
              >
                <button
                  className="absolute text-white -top-10 -right-2 rounded-full cursor-pointer hover:text-red-600 z-50"
                  onClick={() => setDialogOpen({
                    image: '',
                    geoTag: '',
                    isOpen: false
                  })}
                  type="button"
                >
                  <IoCloseOutline size={35} />
                </button>
                <img
                  className="image h-[500px]"
                  src={dialogOpen.image}
                  onClick={() => setDialogOpen({
                    image: '',
                    geoTag: '',
                    isOpen: false
                  })}
                  alt={dialogOpen.geoTag}
                />
                <p className="mb-0"> Geo Tag -  {dialogOpen.geoTag}  </p>
              </dialog>
            </div>
          )
        }
      </div>
    </div>
  );
};

const RowAttachments = ({ row, setDialogOpen }: { row: ReportModel, setDialogOpen: any }) => {
  if (!row.image_1 && !row.image_2 && row.image_3) return <>-</>;

  return (
    <>
      <div className="flex">
        <RowImage setDialogOpen={setDialogOpen} title={row.geo_tag_1} img={row.image_1} />
        <RowImage setDialogOpen={setDialogOpen} title={row.geo_tag_2} img={row.image_2} />
        <RowImage setDialogOpen={setDialogOpen} title={row.geo_tag_3} img={row.image_3} />
      </div>
      <div className="flex">
        <RowImage setDialogOpen={setDialogOpen} title={row.geo_tag_4} img={row.image_4} />
        <RowImage setDialogOpen={setDialogOpen} title={row.geo_tag_5} img={row.image_5} />
        <RowImage setDialogOpen={setDialogOpen} title={row.geo_tag_6} img={row.image_6} />
      </div>
    </>
  );
};

export const RowImage = ({ img, title, setDialogOpen }: { img?: string, title?: string, setDialogOpen: any }) => {
  if (!img) return <></>;

  return (
    <img
      onClick={() => setDialogOpen({
        image: `${process.env.REACT_APP_CLOUDFRONT_URL}/${img}`,
        geoTag: title,
        isOpen: true
      })}
      src={`${process.env.REACT_APP_CLOUDFRONT_URL}/${img}`}
      title={title}
      className="cursor-pointer p-1 w-12 h-12 object-cover rounded-md"
      alt=""
    />
  );
};

export default PlotReportsTab;
