import { ClipPath, Defs, G, Path, Rect, Svg } from "@react-pdf/renderer";

export const GreenCheckbox = ({ width, height }: { width: number, height: number }) => (
  <Svg style={{ width, height }} viewBox="0 0 52 53">
    <Rect x="1" y="1.5" width="50" height="50" rx="3" fill="#015626" stroke="#015626" stroke-width="2" />
    <G clip-path="url(#clip0_544_114)">
      <Path d="M20.15 34.6315L12.0185 26.5L9.24951 29.2495L20.15 40.15L43.55 16.75L40.8005 14.0005L20.15 34.6315Z" fill="white" />
    </G>
    <Defs>
      <ClipPath id="clip0_544_114">
        <Rect  x="1" y="1.5" width="46.8" height="46.8" fill="white" transform="translate(2.60001 3.1001)" />
      </ClipPath>
    </Defs>
  </Svg>

)