import { IoCloseOutline } from "react-icons/io5";

export interface ModalProps {
  shown?: boolean;
  onClose?: () => void;
  dismissible?: boolean;
  contentSize?: string;
  variant?: "dark" | "light";
  children: any;
}

const Modal: React.FC<ModalProps> = ({
  shown = false,
  onClose = () => {},
  dismissible = false,
  contentSize = "lg:max-w-3xl md:max-w-xl sm:max-w-lg",
  variant = "dark",
  children,
}) => {
  const bgClick = () => {
    if (dismissible) {
      onClose();
    }
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  const baseClass = `${contentSize} mx-auto w-full lg:p-6 md:p-10 p-5 rounded-2xl shadow-md relative`;

  const containerClass =
    variant === "dark"
      ? `${baseClass} bg-gray-200 text-black`
      : `${baseClass} bg-gray-50 text-black`;

  return (
    <div onClick={bgClick}>
      {shown && (
        <div className="fixed top-0 left-0 bg-black bg-opacity-50 w-screen h-screen flex items-center justify-center overscroll-none p-4 z-50">
          <div className={containerClass} onClick={handleClick}>
            <button
              className="absolute text-white -top-10 -right-2 rounded-full cursor-pointer hover:text-red-600 z-50"
              onClick={onClose}
              type="button"
            >
              <IoCloseOutline size={35} />
            </button>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
