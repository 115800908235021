import React, { useEffect, useMemo, useState } from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { getActualSectionName, getFloorName, getSectionName } from "../../../utils/stringUtil";
import { ReportSectionType } from "../../../types/app";
import moment from "moment";

interface ReportPageProps {
  report: any;
}

const styles = StyleSheet.create({
  roomAndTypeView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: 13,
    marginVertical: 10,
  },
  sectionName: {
    fontFamily: "CeraPro",
    color: "#17171C",
    fontSize: 18
  },
  subSectionName: {
    fontFamily: "CeraPro",
    fontSize: 14,
    fontWeight: "bold",
    color: "#17171C",
  },
  contentSection: {
    marginVertical: 3,
  },
  contentSectionName: {
    color: "#929292",
    fontSize: 13,
  },
  contentSectionValue: {
    fontSize: 13,
    color: "#454444",
    marginVertical: 4,
  },
  reportImagesView: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  sectionHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    borderRadius: 10,
    padding: 10,
  },
  floorTitleViewTotal: {
    borderWidth: 1,
    fontSize: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 6,
  },
});

const imageWidth = 545 / 3 - 10 - 80 / 3;
const ReportPage: React.FC<ReportPageProps> = ({ report }) => {
  const [sections, setSections] = useState<ReportSectionType[]>([]);

  const [groupedReports, setGroupedReports] = useState<any>({});

  useEffect(() => {
    getSectionsCount();

    // GROUP REPORTS BY SECTION AND THEN BY FLOOR
    const grouped: any = {};
    report.reports.forEach((report: any) => {

      const sectionType = getActualSectionName(report.type_id).toLowerCase();

      if (!grouped[sectionType]) {
        grouped[sectionType] = { title: sectionType, reports: {} };
      }

      if (!grouped[sectionType].reports[report.room_or_comm_id]) {
        grouped[sectionType].reports[report.room_or_comm_id] = [];
      }
      grouped[sectionType].reports[report.room_or_comm_id].push(report);
    });
    setGroupedReports(grouped);

    // console.log('grouped ---<>', grouped);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.reports]);
  const getSectionsCount = () => {
    const regime = report.regime;

    var ext = 0,
      int_1 = 0,
      int_2 = 0,
      com = 0,
      roof = 0;
    var ext_comp = 0,
      int_1_comp = 0,
      int_2_comp = 0,
      com_comp = 0,
      roof_comp = 0;

    Object.entries(regime)
      .filter((element) => element[0].startsWith("floor_"))
      .forEach((room: any) => {
        room[1].forEach((roomI: any) => {
          if (roomI.startsWith("external_")) {
            ext++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              ext_comp++;
            }
          } else if (roomI.startsWith("internals_1_")) {
            int_1++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              int_1_comp++;
            }
          } else if (roomI.startsWith("internals_2_")) {
            int_2++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              int_2_comp++;
            }
          } else if (roomI.startsWith("commissioning_")) {
            com++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              com_comp++;
            }
          } else if (roomI.startsWith("roof_")) {
            roof++;
            if (
              report.reports.find(
                (element: any) =>
                  element.type_id === roomI &&
                  element.room_or_comm_id === room[0]
              )
            ) {
              roof_comp++;
            }
          }
        });
      });

    const nSections: any = [];

    if (ext_comp > 0) {
      nSections.push({
        id: "external",
        title: "External",
        total: ext,
        completed: ext_comp,
      });
    }

    if (int_1_comp > 0) {
      nSections.push({
        id: "internals_1",
        title: "Internals 1st fix",
        total: int_1,
        completed: int_1_comp,
      });
    }

    if (int_2_comp > 0) {
      nSections.push({
        id: "internals_2",
        title: "Internals 2nd fix",
        total: int_2,
        completed: int_2_comp,
      });
    }

    if (com_comp > 0) {
      nSections.push({
        id: "commissioning",
        title: "Commissioning",
        total: com,
        completed: com_comp,
      });
    }

    if (roof_comp > 0) {
      nSections.push({
        id: "roof",
        title: "Roof",
        total: roof,
        completed: roof_comp,
      });
    }

    setSections(nSections);
  };

  const convertToGMT = (dateTimeString: string) => {
    return moment(dateTimeString).format('YYYY-MM-DD HH:mm:ss');
  }

  return (
    <Page size="A4" style={{ paddingTop: 40, paddingBottom: 10 }}>
      {sections.map((section, sectionIndex) => (
        <>
          <View break={sectionIndex > 0} style={{ paddingHorizontal: 40 }}>
            <SectionTitle {...section} />
          </View>

          {
            Object.entries(groupedReports[section.title.toLowerCase()].reports).map(([key, value]: [string, any], index: any) => {
              return (
                <>
                  <View wrap={false}>
                    <Text style={{ ...styles.sectionName, paddingBottom: '20px', paddingTop: index !== 0 ? '20px' : '10px', paddingHorizontal: 40 }}>
                      {report?.floors_no === 0 ? 'Single Floor' : getFloorName(key)}
                    </Text>
                  </View>
                  {
                    value.map((item: any) => {
                      return (
                        <View wrap={false} key={item.type_id} style={{ paddingBottom: '10px' }}>
                          <View style={{ paddingHorizontal: 40 }} >
                            <View wrap={false}>
                              <View wrap={false}>
                                <Text style={{ ...styles.subSectionName }}>
                                  {getSectionName(item.type_id)}
                                </Text>
                              </View>
                              <View style={{ paddingTop: '10px' }} wrap={false}> 
                                <ImageSection item={item} /> 
                              </View>
                            </View>
                            {
                              item?.details && (
                                <View style={{ paddingTop: '12px' }} wrap={false}>
                                  <View style={{ display: 'flex', flexDirection: 'row' }}>

                                    <Text style={{ color: '#17171C', fontSize: '12px', fontFamily: "CeraPro" }}>Details: </Text> 
                                    <Text style={{ paddingLeft: '5px', paddingRight: '40px', color: '#17171C', fontSize: '10px', fontFamily: "CeraProRegular" }}>
                                      { item?.details }
                                    </Text>
                                  </View>
                                </View>
                              )
                            }
                            <View wrap={false} style={{ display: "flex", flexDirection: "row" }}>
                              <View style={{ width: '100%', marginTop: "10px", display: "flex", flexDirection: "column" }}>
                                <View style={{ marginBottom: 10 }}>
                                  <Text style={{ color: '#17171C', fontSize: '12px', fontFamily: "CeraPro" }}>Created At:</Text>
                                  <Text style={{ color: "#595959", fontSize: '10px' }}>{convertToGMT(item!.created_at)?.toLocaleString()}</Text>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View style={{ border: '1px solid black', width: '100%', marginTop: '20px' }} />
                        </View>
                      )
                    })
                  }
                </>
              )
            })
          }
        </>
      ))}
    </Page>
  );
};

export default ReportPage;

const SectionTitle = ({ completed, total, title }: ReportSectionType) => {
  const color = useMemo<string>(() => {
    if (completed === total) return "green";

    if (completed === 0) return "red";

    return "orange";
  }, [completed, total]);
  return (
    <View style={styles.sectionHeader}>
      <View>
        <Text style={{ fontSize: 12, marginTop: 5 }}>{title}</Text>
      </View>
      <View
         style={{ borderWidth: 1, borderRadius: 6, borderColor: color, color, padding: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Text style={{ fontSize: 10 }}>
          {completed} of {total} checks completed
        </Text>
      </View>
    </View>
  );
};


const ImageSection = ({ item }: { item: any}) => {

  const formatCoordinates = (coordinates: string) => {
    const [latitude, longitude] = coordinates.split(',').map(Number);
    const formattedLatitude = latitude?.toFixed(5);
    const formattedLongitude = longitude?.toFixed(5);
    return `${formattedLatitude},${formattedLongitude}`;
  }
  return (
    <View style={styles.reportImagesView}>
      {item?.image_1 && (
        <View style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <Image
            src={item?.image_1}
            style={{
              borderRadius: '5px',
              width: imageWidth,
              height: imageWidth,
              objectFit: "cover",
              margin: 4
            }}
          />
          <Text style={{ fontFamily: 'CeraProRegular', fontSize: '10px', color: '#17171C' }}> 
            { formatCoordinates(item?.geo_tag_1) }
          </Text>
        </View>
      )}
      {item?.image_2 && (
        <View style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <Image
            src={item?.image_2}
            style={{
              borderRadius: '5px',
              width: imageWidth,
              height: imageWidth,
              objectFit: "cover",
              margin: 4,
            }}
          />
          <Text style={{ fontFamily: 'CeraProRegular', fontSize: '10px', color: '#17171C' }}> 
            { formatCoordinates(item?.geo_tag_2) }
          </Text>
        </View>
      )}
      {item?.image_3 && (
        <View style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <Image
            src={item?.image_3}
            style={{
              borderRadius: '5px',
              width: imageWidth,
              height: imageWidth,
              objectFit: "cover",
              margin: 4,
            }}
          />
          <Text style={{ fontFamily: 'CeraProRegular', fontSize: '10px', color: '#17171C' }}> 
            { formatCoordinates(item?.geo_tag_3) }
          </Text>
        </View>
      )}
      {item?.image_4 && (
        <View style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <Image
            src={item?.image_4}
            style={{
              borderRadius: '5px',
              width: imageWidth,
              height: imageWidth,
              objectFit: "cover",
              margin: 4,
            }}
          />
          <Text style={{ fontFamily: 'CeraProRegular', fontSize: '10px', color: '#17171C' }}> 
            { formatCoordinates(item?.geo_tag_4) }
          </Text>
        </View>
      )}
      {item?.image_5 && (
        <View style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <Image
            src={item?.image_5}
            style={{
              borderRadius: '5px',
              width: imageWidth,
              height: imageWidth,
              objectFit: "cover",
              margin: 4,
            }}
          />
          <Text style={{ fontFamily: 'CeraProRegular', fontSize: '10px', color: '#17171C' }}> 
            { formatCoordinates(item?.geo_tag_5) }
          </Text>
        </View>
      )}
      {item?.image_6 && (
        <View style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <Image
            src={item?.image_6}
            style={{
              borderRadius: '5px',
              width: imageWidth,
              height: imageWidth,
              objectFit: "cover",
              margin: 4,
            }}
          />
          <Text style={{ fontFamily: 'CeraProRegular', fontSize: '10px', color: '#17171C' }}> 
            { formatCoordinates(item?.geo_tag_6) }
          </Text>
        </View>
      )}
    </View>
  );
};