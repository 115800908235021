import React from "react";

interface NoResultsComponentProps {
  message?: any;
}

const NoResultsComponent: React.FC<NoResultsComponentProps> = ({
  message = "No matching rows",
}) => {
  return (
    <div className="w-full bg-white p-10 rounded-b-lg flex items-center justify-center text-gray-700 font-medium text-sm">
      {message}
    </div>
  );
};

export default NoResultsComponent;
