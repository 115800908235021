import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormProps } from "../types/app";
import axios from "../plugins/axios";
import Button from "../components/Button";

type Inputs = {
  name: string;
  construction_method: string;
  property_type: string;
  floors_no: number;
};
interface CreatePlotFormProps extends FormProps {
  siteId: number;
}
const CreatePlotForm: React.FC<CreatePlotFormProps> = ({
  onCompleted,
  siteId,
}) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: "",
      construction_method: "",
      property_type: "",
      floors_no: 0,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      setLoading(true);
      try {
        const { data } = await axios.post<{
          success: boolean;
          plot_id: number;
        }>(`/admin/sites/${siteId}/plots`, formData);
        // setSite(data);
        setError(false);
        navigate(`/sites/${siteId}/plots/${data.plot_id}`);
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    } catch (error) {}
  };

  const propertyType = watch("property_type");

  useEffect(() => {
    if (
      propertyType === "semi_detached_bungalow" ||
      propertyType === "detached_bungalow"
    ) {
      setValue("floors_no", 1);
    }
  }, [propertyType, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-xl font-medium mb-4">Create new Plot</h2>
      <div className="my-4">
        <label htmlFor="name" className="text-sm font-medium mb-2">
          Plot Name *
        </label>
        <input
          id="name"
          {...register("name", { required: true, minLength: 3 })}
          placeholder="Enter Site Name (3 characters min)"
          type="text"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.name && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Site name is required and must be more than 3 characters
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="location" className="text-sm font-medium mb-2">
          Construction Method
        </label>
        <select
          id="construction_method"
          {...register("construction_method", { required: true, minLength: 1 })}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#fe5151] focus:border-[#fe5151] block w-full p-2.5 placeholder:text-gray-400"
          placeholder="Choose a construction method"
        >
          <option selected disabled>
            Choose a construction method
          </option>
          <option value="timber_frame">Timber Frame</option>
          <option value="masonry">Masonry</option>
          <option value="steel_frame">Steel Frame</option>
        </select>
        {errors.construction_method && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Construction Method is required
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="warranty_provider" className="text-sm font-medium mb-2">
          Property type
        </label>
        <select
          id="property_type"
          {...register("property_type", { required: true, minLength: 1 })}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#fe5151] focus:border-[#fe5151] block w-full p-2.5 placeholder:text-gray-400"
        >
          <option selected disabled>
            Choose a plot type
          </option>
          <option value="terraced_house">Terraced House</option>
          <option value="semi_detached_house">Semi Detached House</option>
          <option value="detached_house">Detached House</option>
          <option value="semi_detached_bungalow">Semi Detached Bungalow</option>
          <option value="detached_bungalow">Detached Bungalow</option>
          <option value="maisonette">Maisonette</option>
        </select>
        {errors.property_type && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Property type is required
          </span>
        )}
      </div>

      <div className="my-4">
        <label htmlFor="floors_no" className="text-sm font-medium mb-2">
          Floors
        </label>
        <input
          id="floors_no"
          {...register("floors_no", {
            required: true,
            valueAsNumber: true,
            min: 0,
            max: 5,
          })}
          max={5}
          min={0}
          disabled={
            propertyType === "detached_bungalow" ||
            propertyType === "semi_detached_bungalow"
          }
          type="number"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.floors_no && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Floors is required, must be a value between 1 and 5
          </span>
        )}
      </div>

      {error && (
        <div className="font-medium text-red-600">Failed to submit form</div>
      )}

      <Button title="Continue" loading={loading} disabled={loading} />
    </form>
  );
};

export default CreatePlotForm;
