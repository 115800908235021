import React, { useCallback, useEffect, useState } from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import CoverStatusItem from "../parts/CoverStatus";
import { getApiFileURL } from "../../../utils/files";
interface CoverPageProps {
  report: any;
}

const styles = StyleSheet.create({
  coverBody: {
    fontFamily: "CeraPro",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: 40,
  },

  coverLogosView: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  coverLogosLogo: {
    width: 100,
    height: 100,
    borderRadius: "10px",
    objectFit: "contain",
  },
  coverTitle: {
    fontSize: 30,
  },
  coverSubtitle: {
    color: "darkgray",
  },
  twoRowStatus: {
    display: "flex",
    flexDirection: "row",
  },
  logoTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: "row",
    marginTop: 4,
    fontSize: 12,
    fontWeight: 'bold'
  }
});


const CoverPage: React.FC<CoverPageProps> = ({ report }) => {
  return (
    <Page size="A4" style={styles.coverBody}>
      <View style={styles.coverLogosView}>
        {report.company_logo !== '' ? <Image src={report.company_logo} style={styles.coverLogosLogo} /> : <View></View>}
        <View>
          <Image src="/logo.png" style={styles.coverLogosLogo} />
        </View>
      </View>

      <View style={{ width: '100%' }}>
        <Text style={styles.coverTitle}>{report.name} Evidence Report</Text>
        <Text style={styles.coverSubtitle}>{report.site.name}</Text>
        {
          report?.postal_address && (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={{ width: '100%', marginTop: "10px", display: "flex", flexDirection: "column" }}>
                <CoverStatusItem
                  title="Postal Address:"
                  content={`${report.postal_address}`}
                />
              </View>
            </View>
          )
        }
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={{ width: '100%', marginTop: "20px", display: "flex", flexDirection: "column" }}>
            <CoverStatusItem
              title="Location:"
              content={`${report.site.location}`}
            />
            <CoverStatusItem
              title="Client:"
              content={`${report.company}`}
            />
          </View>
          <View style={{ width: '100%', marginTop: "20px", display: "flex", flexDirection: "column" }}>
            <CoverStatusItem
              title="Prepared by:"
              content={`${report.created_by}`}
            />
            <CoverStatusItem
              title="Prepared on:"
              content={`${report.created_at}`}
            />
          </View>
        </View>
      </View>

      <View>
      </View>
    </Page>
  );
};

export const CompanyLogo = ({ img }: { img?: string }) => {
  const [img2, setImage2] = useState("");

  const getFile = useCallback(async () => {
    const val = await getApiFileURL(img);
    setImage2(val);
  }, [img]);

  useEffect(() => {
    getFile();
  }, [getFile]);

  if (!img) return <Image src="#" style={styles.coverLogosLogo} />;

  if (!img2) return <Image src="#" style={styles.coverLogosLogo} />;

  return <Image src={img2} style={styles.coverLogosLogo} />;
};

export default CoverPage;
