import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PaginatedType, UserModel } from "../../types/api";
import useCustomSearchParams from "../../utils/useCustomSearchParams";
import axios from "../../plugins/axios";
import ListViewHeader from "../../components/ListViewHeader";
import CognitoUser from "../../components/CognitoUser";
import { Link } from "react-router-dom";
import Table from "../../components/Table";
import { TiUserDelete } from "react-icons/ti";
import { toast } from "react-toastify";
interface ApiUserModel {
  id: number;
  owner: boolean;
  company: { name: string; id: number };
}
const UsersList: React.FC = () => {
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<ApiUserModel[]>([]);
  const [search] = useCustomSearchParams();
  const [total, setTotal] = useState(0);

  const pagination = useMemo(() => {
    return {
      current_page: parseInt(search.page ?? "1"),
      per_page: parseInt(search.per_page ?? "20"),
    };
  }, [search.page, search.per_page]);

  const getUsersList = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<PaginatedType<ApiUserModel[]>>(
        "/superadmin/users",
        {
          params: {
            page: pagination.current_page,
            per_page: pagination.per_page,
          },
        }
      );
      setUsers(data.items);
      setTotal(data.total);
    } catch (error) {
      setError(true);
      setUsers([]);
      setTotal(0);
    }
    setLoading(false);
  }, [pagination]);

  useEffect(() => {
    getUsersList();
  }, [getUsersList, pagination, search.search]);

  const deleteUser = async (id: number) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to delete this user, this cannot be undone."
      )
    ) {
      try {
        await axios.delete(`/superadmin/users/${id}`);
        toast("User Deleted successfully", { type: "success" });
        await getUsersList();
      } catch (error) {
        toast("Failed to delete user.", { type: "error" });
      }
    }
  };

  const updateUserType = async (user: UserModel) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        'Are you sure you want to update this user ?'
      )
    ) {
      try {
        let url = `superadmin/users/make-admin/${user.id}`;

        if (user.owner) {
          url = `superadmin/users/make-user/${user.id}`;
        }

        await axios.post(url);
        toast("User updated successfully", { type: "success" });
        window.location.reload();
      } catch (error) {
        toast("Failed to update user.", { type: "error" });
      }
    }
  }

  return (
    <div>
      <ListViewHeader title="Users" />

      <Table
        total={total}
        loading={loading}
        error={error}
        rows={users}
        pagination={pagination}
        headers={["Name", "Email", "Company", "Status", "Active Since", "", ""]}
        messages={{
          noResults: "No registered users found",
          apiFailed: "Failed to load users list",
        }}
        renderRow={(fUser) => {
          return (
            <CognitoUser
              key={fUser.id}
              userId={fUser.id}
              render={(user) => (
                <tr className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500">
                  <td className="py-3 pl-6">{user.name}</td>
                  <td className="py-2">{user.email}</td>
                  <td className="py-2">
                    <Link
                      to={`/companies/${fUser.company.id}`}
                      className="text-[#ff1616] hover:text-[#fe5151] flex items-center justify-start font-medium"
                    >
                      {fUser.company.name}
                    </Link>
                  </td>
                  <td className="py-2">{user.status}</td>
                  <td className="py-2">
                    {new Date(user.created_at).toLocaleDateString()}
                  </td>
                  <td className="py-2">
                    <button
                      title="Make Admin"
                      className={
                        user.owner
                          ? "text-white bg-blue-500 px-2 py-1 rounded-md hover:bg-blue-600 w-[110px] mr-2"
                          : "text-white bg-red-500 px-2 py-1 rounded-md hover:bg-red-600 w-[110px] mr-2"
                      }
                      onClick={() => updateUserType(user)}
                    >
                      { user.owner ? 'Make User' : 'Make Admin' }
                    </button>
                  </td>
                  <td className="py-2">
                    <button
                      title="Remove User"
                      className="text-red-500 hover:text-red-600"
                      onClick={() => deleteUser(fUser.id)}
                    >
                      <TiUserDelete />
                    </button>
                  </td>
                </tr>
              )}
            />
          );
        }}
      />
    </div>
  );
};

export default UsersList;
